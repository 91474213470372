import $ from 'jquery'
import mediaelementplayer from 'mediaelement/standalone'
import config from './config'
import bindControls from './controls'
import trackList from './tracklist'
import styles from 'styl/commons/common.styl'
let app = window.app || {};

var Player = function () {
    var self = this;
    let mediaArtist;
    let mediaTrack;

    window.player = self;

    self.plaingRadio = false;
    self.plaingRadioEl = false;

    self.playerModel = {
        repeat: false,
        shuffle: false,
        init: false
    };

    self.controls = $(`.${styles['player-controls']}, .${styles.collapsed_player}`);

    var titlePlace = self.controls,
        downloadBtn = self.controls.find(`.${styles.dl}`);

    self.seekbar = $(`.${styles.progress}`);
    // self.loaded = $('.progress .load-bar');

    var MediaEl = {};

    this.init = function (songs) {

        self.tracklist = new trackList(self);

        window.tracklist = self.tracklist;

        self.tracklist.init(songs, function () {

            var options = config(self, function (mediaElement) {

                self.MediaEl = mediaElement;

                bindControls(self);
                self.tracklist.bindSongs();
            });

            MediaEl = new MediaElement("player", options);
            MediaEl.autoplay = false
        });
    };

    // this.setProgressRail = function(e, media) {
    //
    //     var t = this,
    //         target = e !== undefined ? e.target : media,
    //         percent = null;
    //
    //     // newest HTML5 spec has buffered array (FF4, Webkit)
    //     if (target && target.buffered && target.buffered.length > 0 && target.buffered.end && target.duration) {
    //         // account for a real array with multiple values - always read the end of the last buffer
    //         percent = target.buffered.end(target.buffered.length - 1) / target.duration;
    //     }
    //     // Some browsers (e.g., FF3.6 and Safari 5) cannot calculate target.bufferered.end()
    //     // to be anything other than 0. If the byte count is available we use this instead.
    //     // Browsers that support the else if do not seem to have the bufferedBytes value and
    //     // should skip to there. Tested in Safari 5, Webkit head, FF3.6, Chrome 6, IE 7/8.
    //     else if (target && target.bytesTotal !== undefined && target.bytesTotal > 0 && target.bufferedBytes !== undefined) {
    //         percent = target.bufferedBytes / target.bytesTotal;
    //     }
    //     // Firefox 3 with an Ogg file seems to go this way
    //     else if (e && e.lengthComputable && e.total !== 0) {
    //         percent = e.loaded / e.total;
    //     }
    //
    //     // finally update the progress bar
    //     if (percent !== null) {
    //         percent = Math.min(1, Math.max(0, percent));
    //         // update loaded bar
    //         if (t.loaded) {
    //             t.loaded.width(percent * 100 + '%');
    //         }
    //     }
    //
    //     return percent;
    // };

    this.toggleLoop = function (state) {
        MediaEl.loop = state;
    };

    this.mute = function (state) {
        window.digitalbox && (window.digitalbox.customVolume = state ? "0.1" : MediaEl.getVolume());
        MediaEl.setMuted(state);
    };

    this.toggleState = function (state) {
        self.tracklist.togglePause(!state);
        self.controls.toggleClass(styles.playing, state);
        self.controls.toggleClass(styles.pause, !state);
        $(`.${styles.listen}`).toggleClass(styles.playing, state);
        (state) ? self.controls.find(`.${styles.pause}`).show() : self.controls.find(`.${styles.pause}`).hide();
    };

    this.play = function () {
        self.plaingRadio = false;

        if (!window.tracklist.songModel.fileUrl) {
            window.tracklist.playFirst();
            return;
        }

        MediaEl.pause();
        MediaEl.isInit = true;
        (window.tracklist.songModel.fileUrl) && MediaEl.setSrc([{
            src: window.tracklist.songModel.fileUrl,
            type: 'audio/mp3'
        }]);
        MediaEl.load();

        MediaEl.play();

        $(`.${styles.arr_player}`).css('display','block');

        (!!self.controls.hasClass(styles.inited) && window.tracklist.songModel.fileUrl) ? self.controls.removeClass(styles.inited) : '';

        self.controls.removeClass(styles.radioControll);
        self.seekbar.removeClass(styles.radioControll);
        if (player.plaingRadioEl) {
            player.plaingRadioEl.removeClass(styles.play).removeClass(styles.load).removeClass(styles.pause);
        }
    };

    this.playRadio = function (href, $elem) {
        self.plaingRadio = href;
        self.plaingRadioEl = $elem;
        self.plaingRadioId = $elem.attr('data-radio_id');

        $elem.addClass(styles.loading);

        MediaEl.pause();
        MediaEl.isInit = true;
        MediaEl.setSrc([{src: self.plaingRadio, type: 'audio/mp3'}]);
        MediaEl.load();
        MediaEl.play();

        var $title = $(`.${styles.title}`, $elem);

        $(`.${styles['track-name']} .${styles.artist}`, self.controls).html($title.length ? $title.text() : $elem.attr('data-title'));
        $(`.${styles['track-name']} .${styles.track}`, self.controls).html('');


        var playerImg = $(`.${styles.playerImg} img`),
            radioTop = $(`.${styles.radioTop} img`);


        if ($('img', $elem)[0] || $elem.data('img') ) {
            playerImg.attr('src',  $elem.data('img') || $('img', $elem).attr('src'));
            $(`.${styles.playerImg}`).show();
            playerImg.css('opacity', '1');
        } else {
            if (radioTop[0]) {
                playerImg.attr('src', radioTop.attr('src'));
                $(`.${styles.playerImg}`).show();
                playerImg.css('opacity', '1');
            } else {
                // $(`.${styles.playerImg}`).hide();
            }
        }

        // (!!self.controls.hasClass('inited') && window.tracklist.songModel.fileUrl) ? self.controls.removeClass('inited') : '';

        self.controls.removeClass(styles.inited);
        self.controls.addClass(styles.radioControll);
        self.seekbar.addClass(styles.radioControll);
        $(`.${styles.time}`).css('display', 'none');

        this.showRadioMedia({
            img: $elem.data('img') || $('img', $elem).attr('src') || radioTop.attr('src'),
            title: $title.length ? $title.text() : $elem.attr('data-title')
        })
    };

    this.bindPlayerData = function (songModel) {

        // if (app.rbtAdv && document.location.protocol==='http:' && (document.location.search.indexOf('nr=1')+1)){
        //     if (!$('.group.basic .rbtCont')[0]){
        //         $('.group.basic').append('<div class="rbtCont">div</div>');
        //     }
        //
        //     $('.group.basic .rbtCont').html('');
        //
        //     $('.group.basic .rbtCont').html($('.rbt',songModel.el)[0]);
        // }

        titlePlace.find(`.${styles.playerImg}`).css('opacity', '1');
        titlePlace.find(`.${styles.artist}`).html(songModel.artist);
        titlePlace.find(`.${styles.track}`).html(songModel.track);
        titlePlace.find(`.${styles.duration}`).html(songModel.duration);
        titlePlace.find(`.${styles.votes}.${styles.voteLike}`).html(songModel.likesCount);
        titlePlace.attr(`title`, songModel.track + ' — ' + songModel.artist);
        titlePlace.find(`.${styles.link}`).attr('href', songModel.trackLink);

        mediaArtist = songModel.artist;
        mediaTrack = songModel.track;

        if (!!downloadBtn) {
            downloadBtn.removeClass(styles.ready)
                .removeAttr('href')
                .attr('href', songModel.downloadUrl)
                .removeAttr('download');
            (!!songModel.mp3) && downloadBtn.attr('download', songModel.mp3);

            downloadBtn.addClass(styles.ready);
        }
        self.showMedia(songModel.img);
    };

    this.resume = function () {
        MediaEl.play();
    };

    this.pause = function () {
        MediaEl.pause();
        window.tracklist.togglePause(true);
    };

    this.stop = function () {
        MediaEl.stop();
    };

    this.showMedia = function (img) {
        if (navigator && ('mediaSession' in navigator)) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: mediaTrack,
                artist: mediaArtist,
                artwork: [
                    { src: img.replace(/small|medium|big/,'large')},
                ]
            });

            navigator.mediaSession.setActionHandler('play', () => {
                bindControls.play(self);
            });
            navigator.mediaSession.setActionHandler('pause', () => {
                bindControls.pause(self);
            });

            navigator.mediaSession.setActionHandler('seekto', (details) => {
                MediaEl.currentTime = details.seekTime;
            });

            if (app.isIos) {
                navigator.mediaSession.setActionHandler('seekforward', function () {
                    MediaEl.currentTime = MediaEl.currentTime + 10;
                });

                navigator.mediaSession.setActionHandler('seekbackward', function () {
                    MediaEl.currentTime = MediaEl.currentTime - 10;
                });
            } else {
                navigator.mediaSession.setActionHandler('previoustrack', () => {
                    bindControls.prevTrack(self);
                });

                navigator.mediaSession.setActionHandler('nexttrack', () => {
                    bindControls.nextTrack(self);
                });
            }
        }
    }

    this.showRadioMedia = function ({img, title}) {
        if (navigator && ('mediaSession' in navigator)) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title,
                artist: `Прямой эфир | ${window.app.application?.toUpperCase()}`,
                artwork: [
                    { src: img?.replace(/small|medium|big/,'large')},
                ]
            });
            navigator.mediaSession.setActionHandler('play', () => {
                bindControls.play(self);
            });
            navigator.mediaSession.setActionHandler('pause', () => {
                bindControls.pause(self);
            });
        }
    }

};

export default new Player