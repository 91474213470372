import $ from 'jquery'
import styles from 'styl/commons/common.styl'


let app = window.app || {};

var trackList = function (player) {

    var self = this,
        list;
    self.songModel = {};


    this.init = function (songs, cb) {
        list = songs;
        (!!cb && typeof cb == 'function') && cb.call();
    };

    this.bindSongs = function () {

        $(list).on('click', `.${styles.item}`, function (e) {

            if (e.originalEvent && e.originalEvent.target.closest('a')) {

                if (!EXCLUDE_ZVOOQ) {

                    if ($(e.target).hasClass('playOtherLink') || $(e.target).hasClass(styles.playOther)) {
                        if (window.ya_global) {
                            ya_global.reachGoal('zvooq' + '.' + ($(e.target).attr('decision') || $(e.target).find('.playOtherLink').attr('decision')));
                        }
                    }
                }

                if ($(e.target).hasClass(styles.download) || $(e.target).hasClass(styles.dl)) {
                    if (window.ya_global) {
                        ya_global.reachGoal('downloadButtonClick' + '.' + ($(e.target).attr('decision') || $(e.target).find(`.${styles.dl}`).attr('decision')));
                    }
                }

                return;
            }

            $(`li.${styles.item}`).removeClass(styles.activeFirstLine);
            var artist = $(`.${styles.artist}`, $(this)).text(),
                $self = $(this);


            var playerImg = $(`.${styles.playerImg} img`),
                playlistImg = `.${styles.playlistImg} img`;

            if (app.mobile) {
                $(`.${styles.player}`).on('click', `.${styles.prev}, .${styles.next}, .${styles.arr_player}`, function () {
                    var oldSrc = $(playlistImg, $self).attr('data-src');
                    var newSrc = oldSrc === '/images/unknown_artist.jpg'
                        ? oldSrc.replace(/\.jpg$/i, '_large.jpg')
                        : oldSrc.replace(/_.*/, '_large.jpg');
                    playerImg.attr('src', newSrc);
                });
            }

            if ($self.data('img') || $(playlistImg, $self)[0]) {
                playerImg.attr('src', $self.data('img') || $(playlistImg, $self).attr('data-src'));
                $(`.${styles.playerImg}`).show();
                playerImg.css('opacity', '1');
            } else {
                if ($(`.${styles.collectionTop} img`)[0]) {
                    playerImg.attr('src', $(`.${styles.collectionTop} img`).attr('src'));
                    $(`.${styles.playerImg}`).show();
                    playerImg.css('opacity', '1');
                } else {
                    $(`.${styles.playerImg}`).hide();
                }
            }

            // $.ajax({
            //     url:'/utils/image/' + artist,
            //     type:'GET',
            //     dataType: 'JSON',
            //     success:
            //         function(data){
            //
            //             if (data && data.status == 'success' && data.image){
            //                 $('.playerImg img').attr('src',data.image);
            //                 $('.playerImg').show();
            //             }else{
            //                 if ($('.playlistImg img',$self)[0]){
            //                     $('.playerImg img').attr('src',$('.playlistImg img',$self).attr('src'));
            //                     $('.playerImg').show();
            //                 }else{
            //                     if ($('.collectionTop img')[0]){
            //                         $('.playerImg img').attr('src',$('.collectionTop img').attr('src'));
            //                         $('.playerImg').show();
            //                     }else{
            //                         $('.playerImg').hide();
            //                     }
            //                 }
            //             }
            //         }
            // });



            var target = $(this);

            if (!target.find(`.${styles.play}`).attr('data-url')) return;

            target.removeClass(styles.error);
            if (target.hasClass(styles.no_file)) {
                e.preventDefault();
                return false;
            }


            $(`.${styles.item}.${styles.load}`, list).removeClass(styles.load);

            self.bindSongModel($(this), function () {

                if (!!target.find(`.${styles.play}`).attr('data-url').length) {
                    const songStr =`${target.data('artist')} - ${target.data('title')}`;
                    if (target.hasClass(styles.pause)) {
                        target.find(`.${styles.play}`).attr('aria-label', 'Пауза')
                        target.find(`.${styles.play}`).attr('title', `Остановить ${songStr}`)

                        player.resume();
                        return;
                    }

                    if (target.hasClass(styles.active)) {
                        target.addClass(styles.pause);
                        target.find(`.${styles.play}`).attr('aria-label', 'Слушать')
                        target.find(`.${styles.play}`).attr('title', `Слушать ${songStr}`)
                        player.pause();
                    } else {
                        const oldEl = list.find(`.${styles.active}`)
                        const oldSongStr =`${oldEl.data('artist')} - ${oldEl.data('title')}`;
                        oldEl.removeClass(styles.active);
                        oldEl.find(`.${styles.play}`).attr('aria-label', 'Слушать')
                        oldEl.find(`.${styles.play}`).attr('title', `Слушать ${oldSongStr}`)

                        list.find(`.${styles.pause}`).removeClass(styles.pause);
                        target.find(`.${styles.play}`).attr('aria-label', 'Пауза')
                        target.find(`.${styles.play}`).attr('title', `Остановить ${songStr}`)

                        self.songModel.el.addClass(styles.load);
                        player.play();

                        if (window.ya_global && ya_global.reachGoal) {
                            ya_global.reachGoal('playButtonClick');
                        }
                    }

                    return false;

                }/* else {
                        e.preventDefault();

                        // if (!app.token && !self.songModel.fileUrl ) {
                        //     Modal.showNotification('need_auth');
                        //     return false;
                        // }

                        !$(e.target).hasClass('dl') && $(e.target).addClass('load');


                        require(['#common#/../req/requester.js','#common#/../req/request.handler.js'], function(req, reqHandler){

                            var cback = function () {

                                if ($(e.target).hasClass('dl')) {
                                    if (window.ya_global) {
                                        ya_global.reachGoal('downloadButtonClick');
                                    }
                                    $(e.currentTarget).find('a.dl').get(0).click();
                                    !!window.ga && ga('send', 'event', 'download', 'from_tracklist');
                                } else {
                                    $(e.target).click();
                                }

                            };

                            var handler = {};

                            if (!window.app.proxied){

                                handler = new reqHandler(self, cback, self.songModel.songId);

                                req.get(self.songModel.songId, function(resp) {
                                    handler(resp);
                                });

                            } else {

                                handler = new reqHandler(self, cback);

                                req.proxy(function(resp) {
                                    handler(resp);
                                });
                            }

                        });

                    }*/
            });

        });
    };

    this.excludeTrack = function (cb) {
        self.songModel.el.addClass(styles.no_file);
        (!!cb && typeof cb == 'function') && cb.call();
    };
    this.highLightTrack = function () {

        if (self.songModel.el && !self.songModel.el.hasClass(styles.active)) {

            self.songModel.el
                .addClass(styles.active)
                .addClass(styles.played)
                .removeClass(styles.load);

            player.bindPlayerData(self.songModel);
        }
    };

    this.togglePause = function (state) {
        self.songModel.el && self.songModel.el.toggleClass(styles.pause, state);
    };

    this.bindSongModel = function (item, cb) {
        if (!item.is(self.songModel.el)) {
            self.songModel = {
                artist: item.find(`.${styles.artist}`).text(),
                track: item.find(`.${styles.track}`).text(),
                duration: item.find(`.${styles.duration}`).text(),
                likesCount: item.find(`.${styles.votes}.${styles.voteLike}`).text(),
                mp3: item.find(`.${styles.download} a.${styles.dl}`).attr('download'),
                el: item,
                fileUrl: item.find(`.${styles.play}`).attr('data-url'),
                downloadUrl: item.find(`.${styles.download} a.${styles.dl}`).attr('href'),
                songId: item.find(`.${styles.play}`).attr('data-id'),
                baseId: item.attr('data-baseid'),
                stint: item.attr('stint') !== undefined,
                sourceId: item.parent('ul').attr('data-id'),
                collectionType: item.parent('ul').attr('data-collection-type'),
                collectionId: item.parent('ul').attr('data-collection-id'),
                trackLink: item.find('[data-tracklink]').attr('href'),
                isSend: false,
                img: item.data('img') || item.find(`.${styles.playlistImg} img`).attr('data-src')  ||''
            };
        }

        !!cb && typeof cb == 'function' && cb.call();

    };

    this.bindTrackData = function (resp, cb) {
        var data = resp.data;
        $(`.${styles.play}`, self.songModel.el)
            .removeAttr('data-id').attr('data-id', ((!!data.owner_id) ? data.owner_id + '_' + data.id : data.id))
            .removeAttr('data-url').attr('data-url', data.url)
            .removeAttr('download').attr('download', data.artist + ' - ' + data.title + '.mp3');

        $(`a.${styles.dl}`, self.songModel.el).attr('href', data.url).addClass(styles.ready);

        self.songModel.songId = (!!data.owner_id) ? data.owner_id + '_' + data.id : data.id;
        self.songModel.fileUrl = data.url;

        !!cb && (typeof cb == 'function') && cb.call(this, resp);
    };

    this.playFirst = function () {
        var $list = list;

        if ($list.hasClass('mainSongs')) {
            $list = $list.filter('.mainSongs')
        }

        $(`.${styles.item}`, $list).first().click();
        //$('.mobile .songs .item').add('.desktop .songs .item').first().click();
    };

    this.repeatClick = function () {
        self.songModel.el.click();
    };

    this.repeat = function () {
        player.pause();
        player.play(0);
    };

    this.prev = function () {
        (self.songModel.el.prevAll().not('.no_file').first().get(0)) ? self.songModel.el.prevAll().not('.no_file').first().first().click() : self.playFirst();
    };

    this.next = function () {
        ($(` ~ .${styles.item}:not(.${styles.no_file})`, self.songModel.el).first().get(0)) ? $(` ~ .${styles.item}:not(.${styles.no_file})`, self.songModel.el).first().click() : player.pause();
    };

    this.shuffle = function shuffle() {
        var shuffleParrent = self.songModel.el.parent().addClass(styles.shuffle),
            notPlayed = shuffleParrent.find(`.${styles.item}`).not(`.${styles.played}`);

        if (notPlayed[0]) {
            var playTarget = $(notPlayed[Math.floor(Math.random() * notPlayed.length)]);
        } else {

            list.find(`.${styles.item}`).removeClass(styles.played);
            self.shuffle();
            return;
        }

        if (playTarget.parents(`.${styles['desktop-sidebar']}`)[0]) {
            playTarget.addClass(styles.played);
            self.shuffle();
            return;
        }

        playTarget.click();
        $('body, html').animate({ scrollTop: (playTarget.offset().top - 15 - $(`.${styles.header}`).height()) }, '800', 'swing');

    };

    return this;
};

export default trackList
