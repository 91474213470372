const baseAuthDTO = {
    user_id: +window.app.user_id,
    auth_id: +window.app.auth_provider_id,
    auth_provider: window.app.auth_provider,
}

const sendStatistic = async (body) => {
    try {
        const res = await fetch('/events/events', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json'
            }
        })
        if (res.ok) {
            return await res.json()
        }
    } catch (e) {
        console.debug('Statistic error: ', e.message)
    }
}

class MyStatistic {

    /**
     * @param {Date} params.timestamp time.
     * @param {number} params.track_id track id.
     * @param {Object | null} params.collection collection.
     * @param {number} params.collection.id Album or collection id.
     * @param {"artists"|"albums"|"ost"|"collections"|"radio"|"national"|"hidden"|"genre"|"books"|"games"|"seasons"|"shows"|"holidays"|"children"|"sport"|"musical"|"9may"|"tops"|"songs"|"new"|"tiktok"|"retro"|"diskoteka"|"birthday"|"instruments"|"standup_paramount"|"standup_volya"|"standup_openmice"|"standup_tnt"|"standup_komik_v_gorode"|"festivals"|"collections_clean"|"artists_clean"|"albums_clean"} params.collection.type Type album or collection.
     */
    static async playerStart(params) {
        if(!window?.app?.kafka_events){
            return
        }
        const body = {
            type: "PlayerStart",
            timestamp: params.timestamp,
            track_id: params.track_id,
            collection: params.collection,
            source: window.app.application
        }
        if (window.app.authorized) {
            Object.assign(body, baseAuthDTO)
        }
        sendStatistic(body)
    }

    /**
     * @param {Date} params.timestamp time.
     * @param {number} params.track_id track id.
     * @param {Object | null} params.collection collection.
     * @param {number} params.collection.id Album or collection id.
     * @param {"artists"|"albums"|"ost"|"collections"|"radio"|"national"|"hidden"|"genre"|"books"|"games"|"seasons"|"shows"|"holidays"|"children"|"sport"|"musical"|"9may"|"tops"|"songs"|"new"|"tiktok"|"retro"|"diskoteka"|"birthday"|"instruments"|"standup_paramount"|"standup_volya"|"standup_openmice"|"standup_tnt"|"standup_komik_v_gorode"|"festivals"|"collections_clean"|"artists_clean"|"albums_clean"} params.collection.type Type album or collection.
     */
    static async playerPlay(params) {
        if(!window?.app?.kafka_events){
            return
        }
        const body = {
            type: "PlayerPlay",
            timestamp: params.timestamp,
            track_id: params.track_id,
            collection: params.collection,
            source: window.app.application

        }
        if (window.app.authorized) {
            Object.assign(body, baseAuthDTO)
        }
        sendStatistic(body)
    }

    /**
     * @param {Date} params.timestamp time.
     * @param {number} params.track_id track id.
     * @param {Object | null} params.collection collection.
     * @param {number} params.collection.id Album or collection id.
     * @param {"artists"|"albums"|"ost"|"collections"|"radio"|"national"|"hidden"|"genre"|"books"|"games"|"seasons"|"shows"|"holidays"|"children"|"sport"|"musical"|"9may"|"tops"|"songs"|"new"|"tiktok"|"retro"|"diskoteka"|"birthday"|"instruments"|"standup_paramount"|"standup_volya"|"standup_openmice"|"standup_tnt"|"standup_komik_v_gorode"|"festivals"|"collections_clean"|"artists_clean"|"albums_clean"} params.collection.type Type album or collection.
     */
    static async playerPause(params) {
        if(!window?.app?.kafka_events){
            return
        }
        const body = {
            type: "PlayerPause",
            timestamp: params.timestamp,
            track_id: params.track_id,
            collection: params.collection,
            source: window.app.application

        }
        if (window.app.authorized) {
            Object.assign(body, baseAuthDTO)
        }
        sendStatistic(body)
    }

    /**
     * @param {Date} params.timestamp time.
     * @param {number} params.track_id track id.
     * @param {Object | null} params.collection collection.
     * @param {number} params.collection.id Album or collection id.
     * @param {"artists"|"albums"|"ost"|"collections"|"radio"|"national"|"hidden"|"genre"|"books"|"games"|"seasons"|"shows"|"holidays"|"children"|"sport"|"musical"|"9may"|"tops"|"songs"|"new"|"tiktok"|"retro"|"diskoteka"|"birthday"|"instruments"|"standup_paramount"|"standup_volya"|"standup_openmice"|"standup_tnt"|"standup_komik_v_gorode"|"festivals"|"collections_clean"|"artists_clean"|"albums_clean"} params.collection.type Type album or collection.
     */
    static async trackLike(params) {
        if(!window?.app?.kafka_events){
            return
        }
        const body = {
            type: "TrackLike",
            timestamp: params.timestamp,
            track_id: params.track_id,
            collection: params.collection,
            source: window.app.application

        }
        if (window.app.authorized) {
            Object.assign(body, baseAuthDTO)
        }
        sendStatistic(body)
    }

    /**
     * @param {Date} params.timestamp time.
     * @param {number} params.track_id track id.
     * @param {Object | null} params.collection collection.
     * @param {number} params.collection.id Album or collection id.
     * @param {"artists"|"albums"|"ost"|"collections"|"radio"|"national"|"hidden"|"genre"|"books"|"games"|"seasons"|"shows"|"holidays"|"children"|"sport"|"musical"|"9may"|"tops"|"songs"|"new"|"tiktok"|"retro"|"diskoteka"|"birthday"|"instruments"|"standup_paramount"|"standup_volya"|"standup_openmice"|"standup_tnt"|"standup_komik_v_gorode"|"festivals"|"collections_clean"|"artists_clean"|"albums_clean"} params.collection.type Type album or collection.
     */
    static async trackDislike(params) {
        if(!window?.app?.kafka_events){
            return
        }
        const body = {
            type: "TrackDislike",
            timestamp: params.timestamp,
            track_id: params.track_id,
            collection: params.collection,
            source: window.app.application

        }
        if (window.app.authorized) {
            Object.assign(body, baseAuthDTO)
        }
        sendStatistic(body)
    }

    /**
     * @param {Date} params.timestamp time.
     * @param {number} params.track_id track id.
     * @param {Object | null} params.collection collection.
     * @param {number} params.collection.id Album or collection id.
     * @param {"artists"|"albums"|"ost"|"collections"|"radio"|"national"|"hidden"|"genre"|"books"|"games"|"seasons"|"shows"|"holidays"|"children"|"sport"|"musical"|"9may"|"tops"|"songs"|"new"|"tiktok"|"retro"|"diskoteka"|"birthday"|"instruments"|"standup_paramount"|"standup_volya"|"standup_openmice"|"standup_tnt"|"standup_komik_v_gorode"|"festivals"|"collections_clean"|"artists_clean"|"albums_clean"} params.collection.type Type album or collection.
     * @param {Object} params.data data.
     * @param {number} params.data.duration all duration.
     * @param {number} params.data.listened listened.
     */
    static async playerListened(params) {
        if(!window?.app?.kafka_events){
            return
        }
        const body = {
            type: "PlayerListened",
            timestamp: params.timestamp,
            track_id: params.track_id,
            collection: params.collection,
            data: params.data,
            source: window.app.application

        }
        if (window.app.authorized) {
            Object.assign(body, baseAuthDTO)
        }
        sendStatistic(body)
    }

    /**
     * @param {Date} params.timestamp time.
     * @param {number} params.track_id track id.
     * @param {Object | null} params.collection collection.
     * @param {number} params.collection.id Album or collection id.
     * @param {"artists"|"albums"|"ost"|"collections"|"radio"|"national"|"hidden"|"genre"|"books"|"games"|"seasons"|"shows"|"holidays"|"children"|"sport"|"musical"|"9may"|"tops"|"songs"|"new"|"tiktok"|"retro"|"diskoteka"|"birthday"|"instruments"|"standup_paramount"|"standup_volya"|"standup_openmice"|"standup_tnt"|"standup_komik_v_gorode"|"festivals"|"collections_clean"|"artists_clean"|"albums_clean"} params.collection.type Type album or collection.
     * @param {Object} params.data data.
     * @param {number} params.data.duration all duration.
     * @param {number} params.data.listened listened.
     */
    static async playerSkip(params) {
        if(!window?.app?.kafka_events){
            return
        }
        const body = {
            type: "PlayerSkip",
            timestamp: params.timestamp,
            track_id: params.track_id,
            collection: params.collection,
            data: params.data,
            source: window.app.application

        }
        if (window.app.authorized) {
            Object.assign(body, baseAuthDTO)
        }
        sendStatistic(body)
    }
}

export default MyStatistic