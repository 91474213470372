import '../../../../_newcommon/js/events/zvuk';
import $ from 'jquery'
import player from 'js/modules/player/player'
import kinetic from 'js/modules/kinetic'
import reloadLazy from 'js/modules/lazyLoad'
import 'jquery-ui-sortable-npm' // 'jquery-ui-sortable-npm/jquery-ui-sortable.min'
import init from 'js/modules/ajaxPagination'
import listen from 'js/modules/listenAll'
import styles from 'styl/commons/common.styl'

import Swiper from 'swiper/bundle';
import {Navigation} from 'swiper/modules';

$(document).ready(function () {
    document.querySelectorAll('.swiper').forEach((item, index) => {
        item.id = `swiper-${index}`;
        new Swiper(`#${item.id}`, {

            modules: [Navigation],

            slidesPerView: "auto",
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    })
    //Image error handler
    document.querySelectorAll(`img[loading="lazy"]`).forEach(item => {
        item.addEventListener('error', e => {
            e.target.src = e.target.dataset.src || 'error'
        }, {once: true})
        item.src = item.src
    })

    $.each($(`.${styles.kinetic}`), function () {
        kinetic.init($(this));
    });

    let $search = $('#main-search');

    $('button[type="submit"]', $search).prop('disabled', false);

    $search.on('submit', function (e) {
        if (!$(`#${styles.search}`).val()) {
            e.preventDefault();
        }
    });

    if ($(`.${styles.shared_buttons}`).hasClass('shared_mobile')) {
        $('.ya-share2__list').css({
            'float': 'right'
        });
    }

    //Если аватарки юзера нет - грузим заглушку принудительно.
    if(app.authorized) {
        var pug = $(`.${styles.myMusic} > img`),
            myUrl = pug.attr('data-src');
        function checkExists(imageUrl, callback) {
            var img = new Image();
            img.onerror = function() {
                callback(false);
            };
            img.src = imageUrl;
        }
        checkExists(myUrl, function(exists) {
            if(!exists) {
                pug.removeAttr('data-src')
                    .removeAttr('lazyload');
                pug.attr('src','/images/user_default.jpg');
            }
        });
    }

    require(['js/modules/radio.js']);

    player.init($(`.${styles.songs}`));

    let $searchArtist = $('.searchArtist');
    $searchArtist.on('change', searchArtistFun);

    function searchArtistFun() {
        !!window.ga && ga('send', 'event', 'search', 'clickSearchArtist');
        $searchArtist.off('change', searchArtistFun);
    }

    require(['js/ad/banners.js']);
    require(['js/modules/topmenu.js']);
    require(['js/modules/alphabet-toggler.js']);
    require(['js/modules/dropdownmenu.js']);
    
    init(reloadLazy);

    /*Popular*/
    $(`.${styles['module-popular']} .${styles.showAll}`).on('click', function () {
        $(`.${styles.collapsedBox}`).toggleClass(styles.collapsedBoxOpen).toggleClass(styles.collapsedBoxMin);
    });
    /*End Popular*/


    /* shared-button */
    $("#show-share").on("click", function () {
        $(`.${styles.shared_buttons} .${styles.support}`).slideToggle(100, function () {
            $(`#${styles.share}`).toggle();
        });
    });

    //Hamburger menu
    const hamburger = $(`#${styles['hamburger-icon']}`),
        hamburgerMenu = $(`#${styles.mobileMenuShow}`);

    hamburger.click(function () {
        hamburger.toggleClass(styles.active);
        hamburgerMenu.toggleClass(styles.down);
    });
    /*End Hamburger Menu*/


    //listen

    listen($(`.songsListen .${styles.item}`))

    /* auth */
    require(['js/modules/auth.js']);

    /* favorite */
    require(['js/modules/favorite.js']);

    /* playlist */
    require(['js/modules/playlist.js']);

    /* sortable */
    require(['js/modules/sortable.js']);

    /* sortableFavorite */
    require(['js/modules/sortableFavorite.js']);

    /* search and add in playlist */
    require(['js/modules/search_inPlaylist.js']);

    /* Suggests */
    require(['js/modules/suggests.js']);

    /* Filter */
    function parseSearchQuery(searchQuery) {
        searchQuery = searchQuery || location.search;
        if (searchQuery.length <= 1) return {};
        return searchQuery.slice(1)
            .split('&')
            .reduce(function (acc, elem) {
                elem = elem.split('=');
                acc[elem[0]] = elem[1];
                return acc
            }, {});
    }

    function stringifySearchQuery(sqobj) {
        return '?' + Object.keys(sqobj).map(function (key) {
            return key + '=' + sqobj[key]
        }).join('&')
    }

    $(`.${styles.filters} .${styles.sort} > *`).click(function (e) {
        let sort = $(this).data('sort');
        if (!sort) return;
        let parsed = parseSearchQuery();
        parsed.sort = sort;
        if (sort !== 'popular') {
            location.search = stringifySearchQuery(parsed);
        } else {
            delete parsed.sort;
            if(Object.keys(parsed).length !== 0) {
                location.href = location.pathname + stringifySearchQuery(parsed);
            } else {
                location.href=location.pathname;
            }
        }
    });

    /* Start globalChoice */
    var $globalChoice = $(`#${styles.globalChoice}`);
    var tags = {
        genre: $globalChoice.data('genretag') || undefined,
        country: $globalChoice.data('countrytag') || undefined
    };
    var globalChoice =$(`#${styles.globalChoice} .${styles.choice}, #${styles.globalChoice} .${styles.globalTitle}`);
    var filterType = $globalChoice.data('type');
    var filterCollectionType = $globalChoice.data('collection-type');
    var busy = false;
    var opened = false;
    var closeFilter = function (e) {
        if (!$(e.target).parents(`#${styles.globalChoice}`).length && !$(e.target).is(`#${styles.globalChoice}`)) {
            $(document).off('click', closeFilter);
            $globalChoice.slideUp(0);
            opened = false;
        }
    };
    $(`#${styles.globalChoice} .${styles.close}`).click(function () {
        $(document).off('click', closeFilter);
        $globalChoice.slideUp(0);
        opened = false;
    });

    $(`button.${styles.filter}`).click(function () {
        if (opened && !$(this).parents(`#${styles.globalChoice}`).length) {
            $(document).off('click', closeFilter);
            $globalChoice.slideUp(0);
        } else {
            $globalChoice.slideDown(0);
            setTimeout(function () {
                $(document).on('click', closeFilter)
            }, 0);
        }
        opened = !opened;
    });
    $(`#${styles.globalChoice} .${styles.more}`).on('click', function () {
        $(this).parents(`.${styles.list}`).siblings(`.${styles.list}`).removeClass(styles.opened);
        $(this).parents(`.${styles.list}`).toggleClass(styles.opened);
    });

    function apply() {
        let parsed = parseSearchQuery();
        Object.keys(tags)
            .forEach(function (tagName) {
                if (tags[tagName]) {
                    parsed[tagName + '_tag'] = tags[tagName];
                } else {
                    delete parsed[tagName + '_tag'];
                }
            });
        location.search = stringifySearchQuery(parsed);
    }

    $(`button.${styles.apply}`).on('click', apply);
    $(`button.${styles.clear}`).on('click', function () {
        tags.genre = tags.country = undefined;
        globalChoice.removeClass(styles.choice_inactive).removeClass(styles.choice_inactive);
        $(`#${styles.globalChoice} .${styles.globalTitle}`).addClass(styles.choice_active);
        $(this).addClass(styles.choice_active)
    });

    $(`.${styles.choicesFilter} .${styles.close}`).on('click', function () {
        tags[$(this).parents('.genre').length ? 'genre' : 'country'] = null;
        apply()
    });

    globalChoice.click(function () {
        let tagType = $(this).parents('#genre_tag').length ? 'genre' : 'country';
        tags[tagType] = $(this).data('id');
        if (!Object.values(tags).some(function (val) {
            return !!val
        })) {
            globalChoice.removeClass(styles.choice_inactive).removeClass(styles.choice_active);
            $(`#${styles.globalChoice} .${styles.globalTitle}`).addClass(styles.choice_active);
            $(`button.${styles.clear}`).removeClass(styles.choice_inactive);
            return;
        }
        if (busy) return;
        busy = true;

        $.ajax({
            url: "/tag/active-" + filterType,
            type: 'GET',
            dataType: 'json',
            data: {
                genre_tag: tags.genre,
                country_tag: tags.country,
                type: filterCollectionType
            },
            success: function (actives) {
                actives = actives.map(function (id) {
                    return parseInt(id)
                });
                globalChoice.each(function () {
                    var _this = $(this);
                    if (actives.indexOf(_this.data('id')) + 1) {
                        _this.removeClass(styles.choice_inactive)
                    } else {
                        _this.addClass(styles.choice_inactive)
                    }
                    var iof = Object.values(tags).indexOf(_this.data('id'));
                    if (iof + 1 && _this.data('id') || (_this.parents('#genre_tag').length ? 'genre' : 'country') === Object.keys(tags)[iof]) {
                        _this.addClass(styles.choice_active);
                    } else {
                        _this.removeClass(styles.choice_active)
                    }
                    $(`button.${styles.clear}`).removeClass(styles.choice_inactive);
                });
            },
            complete: function () {
                busy = false;
            }

        })
    });
    /* End globalChoice */

    $('.zvukDlButton').on('click', function () {
        let code = $(this).data('code');
        window.location.replace('/zvuk_transfer?q=' + code)
        return false;
    })
});