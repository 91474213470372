import $ from 'jquery'
import "jquery.kinetic";
import styles from 'styl/commons/common.styl'

export default {
    init: function  ($el) {

        var list = $el.find(`.vlist-holder .items, .${styles.kineticList}`),
            itemWidth = list.find('li').first().outerWidth(),
            items = list.find('li').length,
            $target = $el.find(`.js-kinetic, .${styles.kineticCenter}`),
            $left = $el.find(`.js-kinetic-left, .${styles.kineticLeft}`),
            $inner = $el.find(`.js-kinetic-inner, .${styles.kineticList}`),
            $right = $el.find(`.js-kinetic-right, .${styles.kineticRight}`),
            flip = 1,
            enabled = true,
            isNotSwitch = false,
            $flipping = $el.siblings('.flipping').find('.flip');
        var selected = $el.find('.is-selected');

        if(selected.length) {
            $target.scrollLeft(selected.closest('li').index()*itemWidth);
        }

        // if(isTouch.init() || isMobile.any()) {
        //     $el.find('.vlist-holder').addClass('is-touch');
        //     $left.hide();
        //     $right.hide();
        //     return;
        // }
        var leftArrow = $target.scrollLeft() > 0 ? true : false;
        var rightArrow = (($target.width() + $target.offset().left ) == ($inner.offset().left+$inner.get(0).scrollWidth)) ? false : true;

        var hideArrows = styles.hideArrows != undefined ? styles.hideArrows : 'hideArrows';
        var hidden = styles.hidden != undefined ? styles.hideArrows : 'hidden';
        if(!leftArrow && !rightArrow){
            $el.addClass(hideArrows);
            $el.removeClass(hidden);
            return;
        }

        $target.kinetic({
            y: false,
            filterTarget: function(target, e){
                if (!/down|start/.test(e.type)){
                    return !(/a|span|div|img/i.test(target.tagName));
                }
            }
        });

        $target.on('scroll',function () {
            recalcArrows();
        });

        if ($left.length) $left.on('click', scrollLeft);
        if ($right.length) $right.on('click', scrollRight);

        function setFlip(){
            let previousFlip = flip;
            let elements = $('.flipping > .flip');
            if(isNotSwitch) return;
            setTimeout(()=>{
                if(previousFlip === flip) {
                    elements.removeClass(styles.active);
                    elements.eq(flip).addClass(styles.active);
                }
                else previousFlip = flip;
            }, 20)
        }

        function recalcArrows () {

            var scrollLeft = $target.scrollLeft();
            leftArrow = scrollLeft > 0;

            rightArrow = Math.abs(($target.width() + $target.offset().left) - ($inner.offset().left+$inner.get(0).scrollWidth)) > 10;

            if ($el.hasClass('auto')){
                flip = Math.floor(scrollLeft / ($inner.get(0).scrollWidth / $flipping.length) + 0.5);
                setFlip()
            }

            if(!leftArrow && !rightArrow){
                $el.addClass(hideArrows);
                return;
            }

            if(leftArrow) {
                $left.removeClass('hide-i');
                $el.addClass(styles['is-scroll-left']);
            } else {
                $left.addClass('hide-i');
                $el.removeClass(styles['is-scroll-left']);
            }

            if(rightArrow) {
                $right.removeClass('hide-i');
                $el.addClass(styles['is-scroll-right']);
            } else {
                $right.addClass('hide-i');
                $el.removeClass(styles['is-scroll-right']);
            }

        }

        setTimeout(function () {recalcArrows();}, 2000);//зачем?

        function scrollLeft () {scroll(-30);}
        function scrollRight () {scroll(30);}
        function scroll (velocity) {

            $target.kinetic('start', { velocity: velocity });

            setTimeout(function () {
                $target.kinetic('stop');
                if($el.hasClass('auto')){
                    let factor = velocity < 0 ? 1 : 0;
                    $target.kinetic("scrollLeft", 856 * (flip - factor));
                }
                recalcArrows();
            }, $target.width()*0.4);
        }

        function approve(startOver = 1){
            setTimeout(function () {
                $target.kinetic('stop');
                let rewind = 856 * flip * startOver;
                $target.kinetic("scrollLeft", rewind);
                recalcArrows();
            }, 490);
        }


        var directionScroll = function () {
            if(enabled) {
                if ($right.hasClass('hide-i')) {
                    $target.kinetic('start', {velocity: -60});
                    approve(0)
                } else {
                    $target.kinetic('start', {velocity: 30});
                    approve();
                }
            }
        };

        if ($el.hasClass('auto')){
            $target.kinetic("scrollLeft", 0);
            var intervalID = setInterval(function () {
                directionScroll()
            }, 5000);

            $(`.${styles.kinetic}, .flip`).mouseover(() => {
                clearInterval(intervalID);
                enabled = false
            })
        }

        $flipping.on('click', (e) => {
            if (e.currentTarget.classList.contains(styles.active)) return;

            let newFlip = $flipping.index(e.target);
            isNotSwitch = true;
            if (newFlip > flip) {
                $target.kinetic('start', {velocity: 30});
            } else {
                $target.kinetic('start', {velocity: -20});
            }

            setTimeout(function () {
                $target.kinetic('stop');
                $target.kinetic("scrollLeft", 856 * newFlip);
                isNotSwitch = false;
                recalcArrows();
            }, 490);
        });

        $el.removeClass('hidden');
    }
}
