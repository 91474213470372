import sendEvent from "../modules/events";

let events = () => {

    const {app = {}} = window;

    document.addEventListener('click', (e) => {

        let {target: targetEl} = e;

        if (targetEl.closest('.zvukDlButton')) {
            sendEvent('ZVUK_DL_ALL');
            sendEvent(app.isMobile ? 'ZVUK_DL_MOBILE' : 'ZVUK_DL_DESKTOP');
        }

        if (targetEl.closest('.zvukPlayButton')) {
            sendEvent('ZVUK_PLAY_ALL');
            sendEvent(app.isMobile ? 'ZVUK_PLAY_MOBILE' : 'ZVUK_PLAY_DESKTOP');
        }

    })

}

if ( document.readyState !== 'loading' ) {
    events();
} else {
    document.addEventListener("DOMContentLoaded", events);
}