let {app} = window;
let {counters = {}} = app;
let {yandex: yandexConfig = null} = counters;

function sendEvent(key, params = {}, cb = () => {}) {

    if (!yandexConfig || !yandexConfig.id) {

        console.log('yandexConfig not defined');

        return false;
    }

    window.ym && ym(yandexConfig.id, 'reachGoal', key, params, cb);

    console.log('sendEvent' + ' ' + key);

}

export default sendEvent