import $ from 'jquery'
import styles from 'styl/commons/common.styl'

export default function($songItems){//нужно перенести в плеер, рядом с playFirst
    let listen = styles.listen !== undefined ? `.${styles.listen}` : '.listen';
    $(`${listen}`).on('click',function () {
        if($songItems.hasClass(styles.active)){
            $songItems = $songItems.filter(`.${styles.active}`);
        }
        $songItems.first().click();
    });
}
