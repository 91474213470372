import $ from 'jquery'
import styles from 'styl/commons/common.styl'
import MyStatistic from "../statistic";

export default function bindControls(player) {

    $(`.${styles.next}`, player.controls).on('click', () => {
        bindControls.nextTrack(player);
    });

    $(`.${styles.prev}`, player.controls).on('click', () => {
        bindControls.prevTrack(player);
    });

    $(`.${styles.dl}`, player.controls).on('click', function (e) {

        if (window.ya_global && window.ya_global.reachGoal) {
            window.ya_global.reachGoal('downloadButtonClick' + '.' + (player.tracklist.songModel.el.find(`.${styles.dl}`).attr('decision')));
        }

    });

    $(`.${styles.play}`, player.controls).on('click', () => {
        bindControls.play(player);
    });

    $(`.${styles.play}`).on('click', () => {
        var item = $(`li.${styles.item}`);
        item.parent().removeClass(styles.shuffle);
        item.removeClass(styles.activeFirstLine);
    });

    $(`.${styles.mute}`, player.controls).on('click', function (e) {
        $(this).toggleClass(styles.active);
        player.mute($(this).hasClass(styles.active));
    });

    $(`.${styles.pause}`, player.controls).on('click', () => {
        bindControls.pause(player);
    });

    $(`.${styles.repeat}`, player.controls).on('click', function (e) {
        $(this).toggleClass(styles.active);
        player.toggleLoop($(this).hasClass(styles.active));
    });

    $(`.${styles.shuffle}`, player.controls).on('click', function (e) {
        $(this).toggleClass(styles.active);
        player.playerModel.shuffle = !player.playerModel.shuffle;
    });
    if (!window.EXCLUDE_ZVOOQ) {
        $(`.${styles['redirect-message']} [href]`).on('click', function () {
            if (ya_global) {
                ya_global.reachGoal('zvooq');
            }
        })
    }
};

bindControls.nextTrack = (player) => {
    if (player.playerModel.shuffle === true) {
        player.tracklist.shuffle();
    } else {
        if (player.MediaEl.loop === true) {
            player.tracklist.repeat();
        } else {
            if(player.tracklist.songModel.el){
                MyStatistic.playerSkip({
                    timestamp: new Date().getTime(),
                    track_id: +player.tracklist.songModel.songId,
                    collection: player.tracklist.songModel.collectionId ? {
                        id: +player.tracklist.songModel.collectionId,
                        type: player.tracklist.songModel.collectionType,
                    } : null,
                    data: {
                        duration: player.tracklist.songModel.el.data('duration'),
                        listened: +player.MediaEl.getCurrentTime().toFixed()
                    }
                })
            }
            player.tracklist.next();
        }
    }
}

bindControls.prevTrack = (player) => {
    if (player.playerModel.shuffle === true) {
        player.tracklist.shuffle();
    } else {
        if (player.MediaEl.loop === true) {
            player.tracklist.repeat();
        } else {
            player.tracklist.prev();
        }
    }
}

bindControls.pause = (player) => {
    player.pause();
    if (player.plaingRadio) {
        player.plaingRadioEl.removeClass(styles.play).addClass(styles.pause);
    }
}

bindControls.play = (player) => {
    if (player.plaingRadio) {
        player.playRadio(player.plaingRadio, player.plaingRadioEl);
        player.plaingRadioEl.removeClass(styles.pause).addClass(styles.play);
    } else if (!!player.tracklist.songModel.fileUrl) {
        if (player.MediaEl.paused) {
            player.resume();
        } else {
            player.play();

            if (window.ya_global && !!ya_global.reachGoal) {
                ya_global.reachGoal('playButtonClick');
            }

        }
    } else {
        player.tracklist.playFirst();

        if (window.ya_global && !!ya_global.reachGoal) {
            ya_global.reachGoal('playButtonClick');
        }

    }
}
