import $ from 'jquery'
import styles from 'styl/commons/common.styl'

export default function (loadCallback, keepUrl) {
    var optionsSupport = false;
    try {
        var options = Object.defineProperty({}, "passive", {
            get: function () {
                optionsSupport = true;
            }
        });
        window.addEventListener("test", null, options);
    } catch (err) {
    }

    function addListener(type, fn, options) {
        if (window.addEventListener) {
            window.addEventListener(type, fn, optionsSupport ? options : false);
        } else {
            window.attachEvent("on" + type, fn);
        }
    }
    loadCallback = loadCallback || function () {};

    var $ajaxContent = $('.ajaxContent');
    if ($ajaxContent[0]) {
        var loading = false;
        var end = false;
        var currentPage = app.paginationPage;
        var pagesHeight = [];
        var this_search = $('.this_search')[0];
        setTimeout(function(currentPage){ pagesHeight[currentPage]=$ajaxContent.offset().top + $ajaxContent.height()},100,currentPage);
        window.addEventListener('popstate',function(evt){
            console.log('popstate', evt.state);
            if(evt.state && evt.state.paginationPage){
                currentPage = evt.state.paginationPage;
            }else{
                currentPage = app.paginationPage
            }
            console.log('popstate change', currentPage)
        });
        if ('scrollRestoration' in history) {
            // Back off, browser, I got this...
            history.scrollRestoration = 'manual';
        }

        addListener('scroll', function (e) {
            var sort = $(`.${styles.sort} .${styles.activated_sort}:first`).attr('data-sort');
            if (!sort) sort = $(`.twoSort .${styles.activated_sort}:first`).attr('data-sort');
            if (!loading) {
                var heightLoad = $ajaxContent.offset().top + $ajaxContent.height(),
                    scrollNow = $(document).scrollTop() + $(window).height();
                if (heightLoad < scrollNow) end = true;

                if ((heightLoad - scrollNow) < 800 && !end) {
                    $ajaxContent.addClass('ajaxContentLoaded');
                    loading = true;
                    var url = $ajaxContent.data('url').replace(/https?:/, '');
                    var isTitle = url.includes('title');
                    if (sort === 'title' && !isTitle) url += '?sort=title';
                    var requestedPage = currentPage+1;
                    $.ajax({
                        url: url,
                        type: 'get',
                        dataType: 'html',
                        data: {
                            'page': requestedPage
                        },
                        success: function (data, _, jqXHR) {
                            var currentPageByServer = parseInt(jqXHR.getResponseHeader('current-page')),
                                lastPageByServer = parseInt(jqXHR.getResponseHeader('last-page'));

                            if (currentPageByServer !== requestedPage) {
                                // если вернули не ту страницу, значит мы вышли за пределы страниц,
                                // или что-то пошло не так
                                end = true;
                                return;
                            }

                            $ajaxContent.append(data);
                            if ($ajaxContent.data('type') !== 'tracks') {
                                //передвигаем .item.clear в конец
                                $(`.${styles.item}.${styles.clear}`, $ajaxContent).appendTo($ajaxContent)
                            }

                            let mainSearchSongs = styles.mainSearchSongs !== undefined ? `.${styles.mainSearchSongs}` : '.mainSearchSongs';
                            $(`${mainSearchSongs} > .${styles.activeFirstLine}:not(:first)`).removeClass(styles.activeFirstLine);

                            loadCallback();
                            $ajaxContent.removeClass('ajaxContentLoaded');


                            setTimeout(function(currentPage){
                                pagesHeight[currentPage+1]=$ajaxContent.offset().top + $ajaxContent.height()
                            },100,currentPage);
                            if (currentPageByServer === lastPageByServer || lastPageByServer === 0) {
                                end = true;
                            }
                            loading = false;

                        },
                        error: function () {
                            $ajaxContent.removeClass('ajaxContentLoaded');
                            loading = false;
                        }
                    });
                }

                var pageByHeight = pagesHeight.findIndex(function(height){ return height > scrollNow});
                pageByHeight = pageByHeight===-1 ? (pagesHeight.length||2)-1 : pageByHeight;
                if(pageByHeight !== currentPage){
                    if(!keepUrl){
                        var newUrl = document.location.pathname;
                        if(currentPage!==1){
                            newUrl = newUrl.replace(new RegExp('/' + (currentPage) + '$'), '')
                        }
                        if (!this_search) {
                            if(pageByHeight!==1){
                                newUrl += '/' + pageByHeight;
                                newUrl = newUrl.replace('//','/');
                            }
                        }
                        if(!newUrl.length) newUrl='/';
                        newUrl += document.location.search + document.location.hash;
                        history.replaceState({paginationPage:pageByHeight}, document.title, newUrl);
                    }
                    currentPage=pageByHeight;
                }
            }
        }, {passive: true});
    }
};